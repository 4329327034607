import { Component, Injector } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ArtifactBaseComponent } from '../artifact-base-component';
import { AppWizService } from 'src/app/core/services/app-wiz.service';

@Component({
  selector: 'app-power-school-attestation',
  templateUrl: './power-school-attestation.component.html',
  styleUrls: ['./power-school-attestation.component.scss']
})
export class PowerSchoolAttestationComponent extends ArtifactBaseComponent{
  constructor(
    private injector: Injector,
    private fb: FormBuilder,
    public override appWizService : AppWizService,
  ) {
    super(injector)
    this.interalForm = this.fb.group({
      confirmation:    [false, [Validators.required, Validators.requiredTrue]]
    });
    this.interalForm.valueChanges.subscribe(this.validateSection.bind(this));
  }
  initJsonData(): void {
    this.jsonData = this.jsonData || { attestation: null };
    this.jsonData.attestation = this.jsonData.attestation || {};
    this.interalForm.patchValue(this.jsonData.attestation)
  }
  validateJsonData(){
    this.artifact.sectionValid = this.interalForm.valid;
  }
  resetJsonData(){
    this.jsonData.attestation = {};
    this.interalForm.reset();
  }

  override processBeforeSubmit(){
    this.jsonData = {attestation:this.interalForm.value};
  }
}
