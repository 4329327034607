<div>
    <div class="description pt-3">You chose not to renew all of your eligible certificates, please indicate the reason
        that you are opting to not renew.</div>
    <div class="container">
        <div class="row">
            <div class="px-2">
                <form [formGroup]="interalForm">
                    <div class="row form-group">
                        <mat-radio-group aria-label="Select an option" class="example-radio-group" formControlName="reason">
                            <mat-radio-button *ngFor="let option of options" [value]="option">{{option}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>