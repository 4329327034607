<div>
    <div class="description py-3">Please provide the date range of PD and total clock hours earned.</div>
    <div class="container">
        <div class="row">
            <div class="px-2 pt-3">
                <div class="row form-group">
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="date" [form]="interalForm" title="Start Date" placeholder="Start Date"
                            controlName="startDate" [minDate]="minFromDate" [maxDate]="maxFromDate"
                            (dateChange)="dateChanges('start', $event)"></app-form-control>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="date" [form]="interalForm" title="End Date" placeholder="End Date"
                            controlName="endDate" [minDate]="minToDate" [maxDate]="maxToDate"
                            (dateChange)="dateChanges('end', $event)"></app-form-control>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <app-form-control type="text" [form]="interalForm" title="Number of clock hours" placeholder="Number of clock hours" controlName="hours"></app-form-control>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>