<div class="py-3">
    <div class="description">Please list the state-approved P-12 educator preparation program class information being used for renewal credit.</div>
    <div class="primary">

        <div class="table-section">
            <h3>RCR Coursework List</h3>
            <span><i></i></span>

            <table class="table table-striped table-mobile-responsive table-mobile-sided"
                *ngIf="jsonData.records.length > 0">
                <thead>
                    <tr>
                        <th scope="col">Course (Prefix & Number)</th>
                        <th scope="col">Course Title</th>
                        <th scope="col">Course Completion Date</th>
                        <th scope="col" *ngIf="!isReadOnly()">Remove</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let element of jsonData.records;let i = index;">
                        <td scope="row" data-content="Course (Prefix & Number)">{{element.code}}</td>
                        <td data-content="Course Title">{{element.title}}</td>
                        <td data-content="Course Completion Date">{{element.completedDate | date:'MM/dd/yyyy'}}</td>
                        <td data-content="Remove" *ngIf="!isReadOnly()"><mat-icon (click)="deleteRecord(element)"
                                style="font-size:32px; color:red;">close</mat-icon></td>
                    </tr>
                </tbody>
            </table>
            <div class="px-2">
                <form [formGroup]="interalForm" (ngSubmit)="createRecord()">
                    <div class="row form-group">
                        <div class="col-md-4 pt-3">
                            <app-form-control [form]="interalForm" title="Course (Prefix & Number)" placeholder="Course (Prefix & Number)"
                                controlName="code" [type]="'text'"></app-form-control>
                        </div>
                        <div class="col-md-4 pt-3">
                            <app-form-control [form]="interalForm" title="Course Title" placeholder="Course Title"
                                controlName="title" [type]="'text'"></app-form-control>
                        </div>
                        <div class="col-md-4 pt-3">
                            <app-form-control [form]="interalForm" title="Course Completion Date"
                                placeholder="Course Completion Date" controlName="completedDate"
                                [type]="'date'"></app-form-control>
                        </div>
                        <div class="col-md-4 pt-3">
                            <button mat-stroked-button color="accent" [disabled]="!interalForm.valid">Add Class</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>