import { Component, Injector } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { ArtifactBaseComponent } from '../artifact-base-component';

@Component({
  selector: 'app-non-renewal',
  templateUrl: './non-renewal.component.html',
  styleUrls: ['./non-renewal.component.scss']
})
export class NonRenewalComponent extends ArtifactBaseComponent{
  options = [
    "I have not met the continuation requirements as outlined in the general information document.",
    "I do not wish to continue the certificate(s) not selected.",
  ]
  constructor(
    private injector: Injector,
    private fb: FormBuilder,
    public override appWizService : AppWizService,
  ) {
    super(injector)
    this.interalForm = this.fb.group({
      reason: ['', [Validators.required]]
    });
    this.interalForm.valueChanges.subscribe(this.validateSection.bind(this));
  }
  initJsonData(): void {
    this.jsonData = this.jsonData || { record: null };
    this.jsonData.record = this.jsonData.record || {};
    this.interalForm.patchValue(this.jsonData.record)
  }
  validateJsonData(){
    this.artifact.sectionValid = this.interalForm.valid;
  }
  resetJsonData(){
    this.jsonData.record = {};
    this.interalForm.reset();
  }

  override processBeforeSubmit(){
    this.jsonData = {record:this.interalForm.value};
  }
}
