import { AnswerModel } from './answer-model';

export class IQuestionModel {
  id: string = '';
  approach: string = '';
  questionText: string = '';
  questionType: string = '';
  postProcessorClass: string = '';
  tooltip: string = '';
  processed: boolean = false;
  isApplicant: boolean = false;
  isApproachQuestion: boolean = false;
  autoSelectAnswers: boolean = false;
  answerType: {name: string, maxSelections: number, dropdownList: string[]} = {name: '', maxSelections: 0, dropdownList: []};
  selectedAnswers: AnswerModel[] = [];
  answers: AnswerModel[] = [];
  docTypes: any[] = [];
  outputSelectedAnswers: boolean = false;
  lockAnswers: boolean = false;

  /*****temp******/
  isFirst = false;
  isLast = false;
  canSubmit = false;
  visible = false;
  responseValue: string = '';
  explanation: string = '';
  nextQuestionId: any;

  // back?: QuestionModel;
  // next?: QuestionModel;
}

export class QuestionModel extends IQuestionModel {

  constructor(options?: IQuestionModel) {
    super();
    if (options) {
      this.id = options.id;
      this.approach = options.approach;
      this.isApplicant = options.isApplicant;
      this.questionText = options.questionText;
      this.questionType = options.questionType;
      this.postProcessorClass = options.postProcessorClass;
      this.tooltip = options.tooltip;
      this.processed = options.processed;
      this.isApproachQuestion = options.isApproachQuestion;
      this.autoSelectAnswers = options.autoSelectAnswers;
      this.answerType = {
        name: options.answerType.name.toLowerCase(),
        maxSelections: options.answerType.maxSelections || 2,
        dropdownList: options.answerType.dropdownList || []
      };

      if (options.autoSelectAnswers && (!options.selectedAnswers || options.selectedAnswers.length==0)) {
        options.selectedAnswers = options.answers;
      }
      if (options.answers) {
        options.answers.forEach((element) => {
          this.answers.push(new AnswerModel(element));
        });
      }
      if (options.selectedAnswers) {
        options.selectedAnswers.forEach((element) => {
          if(options.answerType.name == 'dropdown'){
            // to make selected option from dropdown using ngModel we need to make same object reference value for selectedAnswers and answers
            let selectedAnswerFromAnswers = this.answers.find((answer:any)=>element.answerText == answer.answerText);
            this.selectedAnswers.push(selectedAnswerFromAnswers ? selectedAnswerFromAnswers : new AnswerModel(element));
          }else{
            this.selectedAnswers.push(new AnswerModel(element));
          }
        });
      } 
      else if (options.answerType.name == 'single') {
        this.selectedAnswers = [];
      }
      
      this.responseValue = options.responseValue;
      this.explanation = options.explanation;
      this.nextQuestionId = options.nextQuestionId;
      this.visible = options.visible;
      this.docTypes = options.docTypes;
      this.outputSelectedAnswers = options.outputSelectedAnswers;
      this.lockAnswers = options.lockAnswers;
    }
  }

  toJSON() {
    return {
      id: this.id,
      approach: this.approach,
      isApplicant: this.isApplicant,
      questionText: this.questionText,
      questionType: this.questionType,
      postProcessorClass: this.postProcessorClass,
      tooltip: this.tooltip,
      processed: this.processed,
      isApproachQuestion: this.isApproachQuestion,
      autoSelectAnswers: this.autoSelectAnswers,
      answerType: this.answerType,
      selectedAnswers: this.selectedAnswers.map(i => i.toJSON()),
      answers: this.answers.map(i => i.toJSON()),
      responseValue: this.responseValue,
      explanation: this.explanation,
      nextQuestionId: this.nextQuestionId,
      visible: this.visible,
      docTypes: this.docTypes,
      outputSelectedAnswers: this.outputSelectedAnswers,
      lockAnswers: this.lockAnswers,
    }
  }
}