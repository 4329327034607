import { Component, Injector } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { ArtifactBaseComponent } from '../artifact-base-component';
import { ValidationService } from 'src/app/core/services/validation.service';

@Component({
  selector: 'app-verify-prof-development',
  templateUrl: './verify-prof-development.component.html',
  styleUrls: ['./verify-prof-development.component.scss']
})
export class VerifyProfDevelopmentComponent extends ArtifactBaseComponent{
  minFromDate: Date | string = new Date(1900, 0, 1);
  maxFromDate: Date | string = new Date();
  minToDate: Date | string = new Date(1900, 0, 1);
  maxToDate: Date | string = new Date();

  constructor(
    private injector: Injector,
    private fb: FormBuilder,
    public override appWizService : AppWizService,
  ) {
    super(injector)
    this.interalForm = this.fb.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      hours:    [0, [Validators.required, ValidationService.numericOnly]]
    });
    this.interalForm.valueChanges.subscribe(this.validateSection.bind(this));
  }
  initJsonData(): void {
    this.jsonData = this.jsonData || { record: null };
    this.jsonData.record = this.jsonData.record || {};
    this.interalForm.patchValue(this.jsonData.record)
  }
  validateJsonData(){
    this.artifact.sectionValid = this.interalForm.valid;
  }
  resetJsonData(){
    this.jsonData.record = {};
    this.interalForm.reset();
  }

  override processBeforeSubmit(){
    this.jsonData = {record:this.interalForm.value};
  }

  dateChanges(type: string, event: any) {
    if(type == 'start') {
      this.minToDate = new Date(event);
      this.minToDate.setDate(this.minToDate.getDate() + 1);
    }
    else {
      this.maxFromDate = new Date(event);
      this.maxFromDate.setDate(this.maxFromDate.getDate() - 1);
    }
  }
  

}
