import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DenyApplicationComponent } from 'src/app/views/partials/shared/dialogs/deny-application-dialog/deny-application-dialog.component';
import { RequestApplicantResubmitComponent } from 'src/app/views/partials/shared/dialogs/request-applicant-resubmit-dialog/request-applicant-resubmit-dialog.component';
import { ViewCertDialogComponent } from 'src/app/views/partials/shared/dialogs/view-cert-dialog/view-cert-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    public dialog: MatDialog,
  ) { }

  viewCertificates(alsdeId: string) {
    let data: any = {alsdeId};
    return this.dialog.open(ViewCertDialogComponent, {
      data, width: '500px'
    });
  }

  requestApplicantResubmit(appId: string) {
    let data: any = {appId};
    return this.dialog.open(RequestApplicantResubmitComponent, {
      data, width: '500px'
    });
  }
  denyApplication(appId: string) {
    let data: any = {appId};
    return this.dialog.open(DenyApplicationComponent, {
      data, width: '500px'
    });
  }

  
}
