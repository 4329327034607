import { Component, Injector } from '@angular/core';
import { ArtifactBaseComponent } from '../artifact-base-component';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AppWizService } from 'src/app/core/services/app-wiz.service';
import { ValidationService } from 'src/app/core/services/validation.service';

@Component({
  selector: 'app-rcr-coursework',
  templateUrl: './rcr-coursework.component.html',
  styleUrls: ['./rcr-coursework.component.scss']
})
export class RcrCourseworkComponent extends ArtifactBaseComponent {
  constructor(
    private injector: Injector,
    private fb: FormBuilder, 
    public override appWizService: AppWizService
  ) {
    super(injector);
    this.interalForm = this.fb.group({
      code: new FormControl('', [Validators.required,Validators.maxLength(100)]),
      title: new FormControl('', [Validators.required,Validators.maxLength(100)]),
      completedDate: new FormControl('', [Validators.required,ValidationService.noFutureDate]),
    });
  }

  createRecord(){
    this.jsonData.records.push(this.interalForm.value);
    this.interalForm.reset();
    this.validateSection();
  }
  deleteRecord(record:any){
    this.jsonData.records = this.jsonData.records.filter((i:any)=>i != record);
    this.validateSection();
  }

  initJsonData(){
    this.jsonData = this.jsonData || { records: null };
    this.jsonData.records = this.jsonData.records || [];
  }
  validateJsonData(){
    this.artifact.sectionValid = !!this.jsonData.records.length;
  }
  resetJsonData(){
    this.jsonData.records = [];  
  }
}
